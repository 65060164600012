<template>

    <div class="mb-3 card" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">

        <div>

            <v-container fluid>
                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierName"
                                      :label="$t('erp.lang_ware_supplier_name')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="SupplierCustID"
                                      :label="$t('customers.lang_customerID')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>

                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierStreet"
                                      :label="$t('generic.lang_street')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierStreetNo"
                                      :label="$t('localbee.lang_lbRestaurantStreetNo')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierZip"
                                      :label="$t('customers.lang_cust_zip')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierCity"
                                      :label="$t('settings.lang_city')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>


                </v-layout>

                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierCountry"
                                      :label="$t('generic.lang_country')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierContactPerson"
                                      :label="$t('generic.lang_ansprechpartner')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>


                </v-layout>


                <v-layout wrap>
                    <v-flex md5 xs12>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierContactPersonPhone"
                                      :label="$t('erp.lang_extensionContactPerson')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>

                <v-layout wrap>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierPhone"
                                      :label="$t('generic.lang_telefone')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierFax"
                                      :label="$t('generic.lang_fax')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>

                </v-layout>
                <v-layout wrap>

                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      type="email"
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="suppliereMail"
                                      :label="$t('generic.lang_emailAdresse')"
                                      autocomplete="off"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex md2 xs0>
                    </v-flex>
                    <v-flex md5 xs12>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="supplierWebsite"
                                      :label="$t('generic.lang_Website')"
                                      autocomplete="off"
                                      required
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>

                    <v-flex md12 xs12>
                        <v-textarea outlined
                                    type="email"
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    v-model="supplierNotes"
                                    :label="$t('generic.lang_internalNotes')"
                                    autocomplete="off"
                        >
                        </v-textarea>
                    </v-flex>
                </v-layout>

                <v-layout>
                    <v-flex style="text-align:right;">
                        <v-btn text color="error" @click="goBackToSuppliers">{{ $t('generic.lang_prev') }}</v-btn>
                        <v-btn color="error" v-if="this.$store.getters['permissions/checkPermission']('supplier_delete')" :disabled="(!id || parseInt(id) < 1)" @click="deleteData">{{
                            $t('generic.lang_delete') }}
                        </v-btn>
                        <v-btn color="success" :disabled="supplierName.length < 1" @click="editData">{{
                            $t('generic.lang_edit') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>

        </div>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </div>
</template>

<script>
    import {ENDPOINTS} from '../../../config'
    import {Events} from "../../../plugins/events";
    import mixin from "../../../mixins/KeyboardMixIns";

    export default {
        components: {},
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                id: null,
                supplierName: "",
                SupplierCustID: "",
                supplierStreet: "",
                supplierStreetNo: "",
                supplierZip: "",
                supplierCity: "",
                supplierCountry: "",
                supplierContactPerson: "",
                supplierContactPersonPhone: "",
                supplierPhone: "",
                supplierFax: "",
                suppliereMail: "",
                supplierWebsite: "",
                supplierNotes: "",
            }
        },


        methods: {
            deleteData: function (idsToDelete = []) {
                this.$swal({
                    title: this.$t("erp.lang_deleteSupplier") + '?',
                    text: this.$t("erp.deleteSupplierBody"),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post(ENDPOINTS.ERP.SUPPLIERS.DELETE, {
                            supplierID: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t("erp.supplierDeleted"),
                                    color: "success"
                                });

                                this.goBackToSuppliers();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading()
                });
            },
            /*GO BACK TO SUPPLIER LIST */
            goBackToSuppliers: function () {
                this.$router.push('/erp/storage/supplier');
            },
            /*EDIT SUPPLIER */
            editData: function () {
                let self = this;
                this.axios.post(ENDPOINTS.ERP.SUPPLIERS.UPDATE, {
                    supplierID: this.id,
                    supplierName: this.supplierName,
                    supplierCustNo: this.SupplierCustID,
                    supplierStreetNo: this.supplierStreetNo,
                    supplierStreet: this.supplierStreet,
                    supplierZip: this.supplierZip,
                    supplierCity: this.supplierCity,
                    supplierCountry: this.supplierCountry,
                    supplierContactPerson: this.supplierContactPerson,
                    supplierContactPersonPhone: this.supplierContactPersonPhone,
                    supplierPhone: this.supplierPhone,
                    supplierFax: this.supplierFax,
                    supplierEMail: this.suppliereMail,
                    supplierWebsite: this.supplierWebsite,
                    supplierNotes: this.supplierNotes,


                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message:  this.$t('erp.supplierUpdated'),
                            color: "success"
                        });
                        this.goBackToSuppliers();
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            getData: function () {
                this.loading = true;

                this.id = parseInt(this.$parent.id);
                this.axios.post(ENDPOINTS.ERP.SUPPLIERS.GET, {
                    supplierID: this.id
                }).then((res) => {
                    if (res.status === 200) {
                        this.loading = false;


                        this.supplierName = res.data.formfillData.textFields.supplierName;
                        this.SupplierCustID = res.data.formfillData.textFields.supplierCustNo;
                        this.supplierStreet = res.data.formfillData.textFields.supplierStreet;
                        this.supplierStreetNo = res.data.formfillData.textFields.supplierStreetNo;
                        this.supplierZip = res.data.formfillData.textFields.supplierZip;
                        this.supplierCity = res.data.formfillData.textFields.supplierCity;
                        this.supplierCountry = res.data.formfillData.textFields.supplierCountry;
                        this.supplierContactPerson = res.data.formfillData.textFields.supplierContactPerson;
                        this.supplierContactPersonPhone = res.data.formfillData.textFields.supplierContactPersonPhone;
                        this.supplierPhone = res.data.formfillData.textFields.supplierPhone;
                        this.supplierFax = res.data.formfillData.textFields.supplierFax;
                        this.suppliereMail = res.data.formfillData.textFields.supplierEMail;
                        this.supplierWebsite = res.data.formfillData.textFields.supplierWebsite;
                        this.supplierNotes = res.data.formfillData.textFields.supplierNotes;
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
        },
        mounted() {
            this.getData();
        },
    }
</script>